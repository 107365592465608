.app-layout {
	display: flex;
	align-items: flex-start;
}

.awt-container {
	border-left: 1px dashed transparent;
}

.awt-container.debug-active {
	border-left-color: hsla(312, 98%, 55%, 0.75);
}

.sidebar {
	width: min(20vw, 20rem);
	height: 100vh;

	padding-left: 0 !important;

	overflow: scroll;
}