:root {
	--color-frame-border-dark: rgba(161, 168, 255, 0.2);
	--color-frame-border-light: rgba(57, 57, 70, 0.6);

	--spacing-xl: 6rem;
	--spacing-lg: 2rem;
	--spacing-md: 1.75rem;
	--spacing-sm: 0.75rem;
	--spacing-xs: 0.25rem;
}

html,
body {
	width: 100%;
	height: 100%;

	padding: 0;
	margin: 0;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	line-height: 1.5;
	font-size: 1.1rem;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: #18191a;
}

#root {
	width: 100%;
}

p {
	letter-spacing: 0.02rem;
}

hr {
	opacity: 0.2;
}

button {
	margin-bottom: 2rem;
	padding: 0.5rem 0.75rem;

	border: none;
	border-radius: 0.25rem;
	outline: none;

	color: white;
	font-weight: bold;

	cursor: pointer;

	background: #b83232;
}

button:not(:first-child) {
	margin-left: 1rem;
}

button.enabled {
	background: #32b85a;
}